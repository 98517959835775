import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: () => import('../views/Login.vue')
    },
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        // redirect: '/index',
        children: [
            {
                path: '/index',
                name: 'index',
                meta: {
                    title: '系统首页'
                },
                component: () => import('../views/auth-entity/ppid-overview/PPIDOverviewInfo.vue')
            },
            // {
            //     path: "/tabs",
            //     name: "tabs",
            //     meta: {
            //         title: 'tab标签'
            //     },
            //     component: () => import ( /* webpackChunkName: "tabs" */ "../views/Tabs.vue")
            // },
            {
                path: '/ppid/overview-info',
                name: 'ppid overview info',
                meta: {
                    title: 'ppid概述'
                },
                component: () => import('../views/auth-entity/ppid-overview/PPIDOverviewInfo.vue')
            },
            {
                path: '/bulk/vended/auth-entities',
                name: '批量申请token',
                meta: {
                    title: '批量申请token'
                },
                component: () => import('../views/auth-entity/BulkVendedTokens.vue')
            },
            {
                path: '/bulk/auth-entity/records',
                name: '批量销售token记录',
                meta: {
                    title: '批量销售token记录'
                },
                component: () => import('../views/auth-entity/bulk-vended/BulkVendedList.vue')
            },
            {
                path: '/auth-entities/file-list',
                name: '批量申请token文件列表',
                meta: {
                    title: '批量申请token文件列表'
                },
                component: () => import('../views/auth-entity/BulkVendedAuthEntityFileList.vue')
            },
            {
                path: '/auth-entities-list',
                name: '获取token列表',
                meta: {
                    title: '获取token列表'
                },
                component: () => import('../views/auth-entity/auth-entities/AuthEntitiesList.vue')
            },
            {
                path: '/auth-entity/event-list',
                name: '操作事件记录',
                meta: {
                    title: '操作事件记录'
                },
                component: () => import('../views/auth-entity/handle-event/HandleEventList.vue')
            },
            // 以下是账号相关
            {
                path: '/account/list',
                name: '账号列表',
                meta: {
                    title: '账号列表'
                },
                component: () => import('../views/user/AccountHandle.vue')
            }
        ]
    }
]

const router =createRouter({
    history: createWebHashHistory(),
    routes
})
// const accountStore = useAccountStore(pinia)

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // 修改页面title
    if (to.meta.title) {
        document.title = `${import.meta.env.VITE_APP_TITLE} - ` + to.meta.title
    }
    // 执行登录页面
    if (to.path === '/login') {
        return next()
    }

    // 获取token
    const token = sessionStorage.getItem('token')
    // const token = accountStore.getToken
    if (!token) {
        return next('/login')
    }

    return next()
})

export default router

import { createI18n } from 'vue-i18n'
import localeZH from 'element-plus/es/locale/lang/zh-cn'
import localeEN from 'element-plus/es/locale/lang/en'
import messages from '@/utils/i18n.js'
import 'element-plus/dist/index.css'
import {
    ElButton,
    ElContainer,
    ElSubMenu
} from 'element-plus'
import * as Icons from '@element-plus/icons-vue'

const i18n = createI18n({
    locale: localeZH.name,
    fallbackLocale: localeEN.name,
    messages
})

export default (app) => {
    for (let i in Icons) {
        app.component(i, Icons[i])
    }
    app.use(ElButton, { locale: localeZH }).
    use(ElContainer).
    use(ElSubMenu).
    use(i18n)
}
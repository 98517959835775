import router from "./index.js";

export default {
    // 跳转路由
    changeView(url, queryParams) {
        console.log('change to index')
        router.push({
            path: url,
            query: queryParams
        })
    }
}